.App {
  text-align: center;
}

.App-header {
  background-color: #1d1d1d;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: #ff752e;
}