.login-container {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    padding: 25px;
    border-radius: 17px;
    width: 550px;
}

.login-container h1 {
    text-align: center;
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container input {
    background-color: #f5f5f5;
    margin: 10px 0px;
    padding: 7px 15px;
    font-size: 18px;
    border: 1px solid #f5f5f5;
    outline: none;
    border-radius: 10px;
}

.login-container label.checkbox {
    font-weight: 200;
    font-size: 14px;
}

.login-container label.checkbox > input {
    margin-right: 10px;
}

.login-container label.checkbox > input:checked {
    accent-color: #ef6925;
    color: #fff;
}

.login-container button[type=submit] {
    background-color: #ff752e;
    outline: none;
    border: none;
    color: #fff;
    padding: 7px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 25px;
}