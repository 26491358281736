.layout-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

aside {
    background-color: #eee;
    height: 100vh;
    transition: 1s;
}

.menu-max {
    width: 350px;
}

.menu-min {
    width: 70px
}

.aside-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 23px 25px 17px;
    border-bottom: 1px solid #eee;
}

.aside-heading h1 {
    color: #ff752e;
    flex: 1;
    margin: 0;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
}

.aside-heading button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: .5s;
    font-size: 20px;
}

.aside-heading button:hover {
    color: #ff752e;
}

.aside-menu ul {
    list-style: none;
    padding: 0px 0px;
}

.aside-menu li {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    height: 21px;
    padding: 15px 23px;
    cursor: pointer;
    transition: .5s;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
}

.aside-menu li::before {
    content: "";
    position: absolute;
    left: 0;
    margin: -7px 0 0 -3px;
    width: 6px;
    height: 34px;
    background-color: transparent;
    border-top-right-radius: 10em;
    border-bottom-right-radius: 10em;
    transition: .5s;
}

.menu-max .menu-text {
    width: 100%;
    transition: .5s;
    transition-property: width, opacity;
}

.menu-min .menu-text {
    transition:  .5s;
    transition-property: opacity, width;
    opacity: 0;
    width: 0%;
}

.aside-menu li .menu-icon {
    /* color: #ff752e; */
    width: 25px;
    padding-right: 5px;
}

.aside-menu li:hover .menu-icon {
    color: #fff;
    transition: .5s;
}

.aside-menu li:hover {
    background-color: #fab592;
    color: #fff;
}

.aside-menu li:hover::before {
    background-color: #ff752e;
}

section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.layout-container header {
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff;
    padding: 14px;
    border-bottom: 1px solid #eee;
}

.user-avatar-container {
    margin-right: 10px;
}

.user-avatar-use {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.caretNotRotate {
    transition: .5s;
}

.caretRotate {
    transition: .5s;
    transform: rotate(-180deg);
}

.user-avatar {
    width: 45px;
    height: 45px;
    border-radius: 10em;
    background-position: center;
    background-size: auto 100%;
    margin-right: 10px;
}

.user-avatar-use span {
    padding-right: 10px;
    font-weight: 200;
}

.user-dropdown {
    position: absolute;
    right: 30px;
    border-radius: 10px;
    box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 25%);
    overflow: hidden;
}

.user-dropdown > div.cont {
    width: 165px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    user-select: none;
    overflow: hidden;
}

.show-userD {
    height: 166px;
    margin-top: 10px;
    transition: .5s;
}

.hide-userD {
    height: 0px;
    transition: .5s;
}

.user-dropdown > .before {
    background-color: #fff;
    width: 10px;
    height: 10px;
    position: relative;
    margin: 2px 0 -6px 145px;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    transform: rotate(-45deg);
}

.user-dropdown hr {
    width: 10%;
    border-color: #eee;
}

.user-dropdown a {
    cursor: pointer;
    padding: 5px;
    text-decoration: none;
    color: #1d1d1d;
    transition: .5s;
}

.user-dropdown a:hover {
    opacity: .7;
}

.layouted-content {
    padding: 10px;
}