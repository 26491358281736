.error {
    background-color: red;
    color: #fff;
}

.alert-container {
    border-radius: 15px;
    margin-top: 15px;
    padding: 15px;
    animation: 2s linear 0s alternate show;
    animation-iteration-count: 1;
}

.alert-container h2, p {
    margin: 0;
}

@keyframes show {
    0% {
        opacity: 1;
    } 50% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
}